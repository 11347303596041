.evidence-image-file{
   z-index: 0;
}
table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}
table.dataTable td,
table.dataTable th {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
  text-align: center;
}
table.dataTable.nowrap th,
table.dataTable.nowrap td {
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_length select {
  width: auto;
  display: inline-block;
}
div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}
div.dataTables_wrapper div.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}
div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}
div.dataTables_wrapper div.dataTables_info {
  padding-top: 0.85em;
}
div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
}
div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0;
}

table.dataTable > thead > tr > th:active,
table.dataTable > thead > tr > td:active {
  outline: none;
}
table.dataTable > thead > tr > th:not(.sorting_disabled),
table.dataTable > thead > tr > td:not(.sorting_disabled) {
  padding-right: 30px;
}
table.dataTable > thead .sorting,
table.dataTable > thead .sorting_asc,
table.dataTable > thead .sorting_desc,
table.dataTable > thead .sorting_asc_disabled,
table.dataTable > thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}
table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_desc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}
table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:before {
  right: 1em;
  content: "↑";
}
table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "↓";
}
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_desc:after {
  opacity: 1;
}
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:after {
  opacity: 0;
}

div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
div.dataTables_scrollBody table thead .sorting:before,
div.dataTables_scrollBody table thead .sorting_asc:before,
div.dataTables_scrollBody table thead .sorting_desc:before,
div.dataTables_scrollBody table thead .sorting:after,
div.dataTables_scrollBody table thead .sorting_asc:after,
div.dataTables_scrollBody table thead .sorting_desc:after {
  display: none;
}
div.dataTables_scrollBody table tbody tr:first-child th,
div.dataTables_scrollBody table tbody tr:first-child td {
  border-top: none;
}

div.dataTables_scrollFoot > .dataTables_scrollFootInner {
  box-sizing: content-box;
}
div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
  margin-top: 0 !important;
  border-top: none;
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    justify-content: center !important;
  }
}
table.dataTable.table-sm > thead > tr > th:not(.sorting_disabled) {
  padding-right: 20px;
}
table.dataTable.table-sm .sorting:before,
table.dataTable.table-sm .sorting_asc:before,
table.dataTable.table-sm .sorting_desc:before {
  top: 5px;
  right: 0.85em;
}
table.dataTable.table-sm .sorting:after,
table.dataTable.table-sm .sorting_asc:after,
table.dataTable.table-sm .sorting_desc:after {
  top: 5px;
}

table.table-bordered.dataTable {
  border-right-width: 0;
}
table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  border-left-width: 0;
}
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
  border-right-width: 1px;
}
table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
  border-bottom-width: 0;
}

div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0;
}
div.table-responsive
  > div.dataTables_wrapper
  > div.row
  > div[class^="col-"]:first-child {
  padding-left: 0;
}
div.table-responsive
  > div.dataTables_wrapper
  > div.row
  > div[class^="col-"]:last-child {
  padding-right: 0;
}

.bg-default {
  background-color: #ffef2c;
  background-size: cover;
}

.btn-primary {
  color: #fff;
  background-color: #21b5ea;
  border-color: #21b5ea;
}

.btn-primary:hover {
  color: #fff;
  background-color: #21b5ea;
  border-color: #21b5ea;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #21b5ea;
  border-color: #21b5ea;
}

.btn-primary:hover {
  color: #fff;
  text-decoration: none;
}

.bg-login-image {
  background: url("../img/logorgb_kiki.svg");
  background-position: center;
  background-size: 160px;
  background-repeat: no-repeat;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.sidebar-dark .nav-item .nav-link {
  font-weight: bold;
  color: rgb(0 0 0 / 60%);
}

.sidebar-dark .nav-item .nav-link i {
  font-weight: bold;
  color: rgb(0 0 0 / 60%);
}

.sidebar-dark .sidebar-heading {
  color: rgb(0 0 0 / 40%);
}

.sidebar-dark #sidebarToggle {
  background-color: rgb(0 0 0 / 25%);
}

.sidebar-dark hr.sidebar-divider {
  border-top: 1px solid rgb(0 0 0 / 15%);
}

.sidebar-dark .nav-item .nav-link[data-bs-toggle="collapse"]::after {
  color: rgb(0 0 0 / 50%);
}

.sidebar-dark .sidebar-brand {
  font-weight: bold;
  color: #000;
}

.sidebar-dark .nav-item .nav-link:active,
.sidebar-dark .nav-item .nav-link:focus,
.sidebar-dark .nav-item .nav-link:hover {
  font-weight: bold;
  color: #000;
}

.sidebar-dark .nav-item .nav-link:active i,
.sidebar-dark .nav-item .nav-link:focus i,
.sidebar-dark .nav-item .nav-link:hover i {
  font-weight: bold;
  color: #000;
}

.sidebar-dark #sidebarToggle:hover {
  background-color: rgb(0 0 0 / 25%);
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}

.text-primary {
  color: #868897 !important;
}

.sidebar {
  width: 16rem !important;
}
.sidebar .nav-item .collapse {
  position: relative;
  left: 0;
  z-index: 1;
  top: 0;
  -webkit-animation: none;
  animation: none;
}
.sidebar .nav-item .collapse .collapse-inner {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.sidebar .nav-item .collapsing {
  display: block;
  -webkit-transition: height 0.15s ease;
  transition: height 0.15s ease;
}
.sidebar .nav-item .collapse,
.sidebar .nav-item .collapsing {
  margin: 0 1rem;
}
.sidebar .nav-item .nav-link {
  display: block;
  width: 100%;
  text-align: left;
  padding: 1rem;
  width: 16rem;
}
.sidebar .nav-item .nav-link i {
  font-size: 0.85rem;
  margin-right: 0.25rem;
}
.sidebar .nav-item .nav-link span {
  font-size: 0.85rem;
  display: inline;
}
.sidebar .nav-item .nav-link[data-bs-toggle="collapse"]::after {
  width: 1rem;
  text-align: center;
  float: right;
  vertical-align: 0;
  border: 0;
  font-weight: 900;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
}
.sidebar .nav-item .nav-link[data-bs-toggle="collapse"].collapsed::after {
  content: "\f105";
}
.sidebar .sidebar-brand .sidebar-brand-icon i {
  font-size: 2rem;
}
.sidebar .sidebar-brand .sidebar-brand-text {
  display: inline;
}
.sidebar .sidebar-heading {
  text-align: left;
}
.sidebar.toggled {
  overflow: visible;
  width: 6.5rem !important;
}
.sidebar.toggled .nav-item .collapse {
  position: absolute;
  left: calc(6.5rem + 1.5rem / 2);
  z-index: 1;
  top: 2px;
  -webkit-animation-name: growIn;
  animation-name: growIn;
  -webkit-animation-duration: 200ms;
  animation-duration: 200ms;
  -webkit-animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1),
    opacity cubic-bezier(0, 1, 0.4, 1);
  animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1),
    opacity cubic-bezier(0, 1, 0.4, 1);
}
.sidebar.toggled .nav-item .collapse .collapse-inner {
  -webkit-box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  border-radius: 0.35rem;
}
.sidebar.toggled .nav-item .collapsing {
  display: none;
  -webkit-transition: none;
  transition: none;
}
.sidebar.toggled .nav-item .collapse,
.sidebar.toggled .nav-item .collapsing {
  margin: 0;
}
.sidebar.toggled .nav-item:last-child {
  margin-bottom: 1rem;
}
.sidebar.toggled .nav-item .nav-link {
  text-align: center;
  padding: 0.75rem 1rem;
  width: 6.5rem;
}
.sidebar.toggled .nav-item .nav-link span {
  font-size: 0.65rem;
  display: block;
}
.sidebar.toggled .nav-item .nav-link i {
  margin-right: 0;
}
.sidebar.toggled .nav-item .nav-link[data-bs-toggle="collapse"]::after {
  display: none;
}
.sidebar.toggled .sidebar-brand .sidebar-brand-icon i {
  font-size: 2rem;
}
.sidebar.toggled .sidebar-brand .sidebar-brand-text {
  display: none;
}
.sidebar.toggled .sidebar-heading {
  text-align: center;
}

.sidebar .sidebar-brand {
  height: auto;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 800;
  padding: 1.5rem 1rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  z-index: 1;
}

.sidebar-brand-image {
  width: 135px;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #47b6eb;
  border-color: #47b6eb;
}

.topbar .dropdown-list .dropdown-header {
  background-color: #ffef2c;
  border: 1px solid #ffef2c;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #000;
}

.address-status {
  min-width: 80px;
  text-align: end;
}

#overlay {
  background: #ffffff;
  color: #666666;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 25%;
  opacity: 0.8;
}
.spinner {
  margin: 0 auto;
  height: 64px;
  width: 64px;
  animation: rotate 0.8s infinite linear;
  border: 5px solid #21b5ea;
  border-right-color: transparent;
  border-radius: 50%;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.show-spinner {
  display: block;
}

.hide-spinner {
  display: none;
}

.list-items-route {
  height: 360px;
  overflow-y: auto;
}

.options-btn {
  min-width: 60px;
}

.cbp_tmtimeline {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.cbp_tmtimeline:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3px;
  background: #eee;
  left: 20%;
  margin-left: -6px;
}

.cbp_tmtimeline > li {
  position: relative;
}

.cbp_tmtimeline > li:first-child .cbp_tmtime span.large {
  color: #444;
  font-size: 17px !important;
  font-weight: 700;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmtime span:last-child {
  color: #444;
  font-size: 13px;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel {
  background: #f0f1f3;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
  border-right-color: #f0f1f3;
}

.cbp_tmtimeline > li .empty span {
  color: #777;
}

.cbp_tmtimeline > li .cbp_tmtime {
  display: block;
  width: 23%;
  padding-right: 70px;
  position: absolute;
}

.cbp_tmtimeline > li .cbp_tmtime span {
  display: block;
  text-align: right;
}

.cbp_tmtimeline > li .cbp_tmtime span:first-child {
  font-size: 15px;
  color: #3d4c5a;
  font-weight: 700;
}

.cbp_tmtimeline > li .cbp_tmtime span:last-child {
  font-size: 14px;
  color: #444;
}

.cbp_tmtimeline > li .cbp_tmlabel {
  margin: 0 0 15px 25%;
  background: #f0f1f3;
  padding: 1.2em;
  position: relative;
  border-radius: 5px;
}

.cbp_tmtimeline > li .cbp_tmlabel:after {
  right: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-right-color: #f0f1f3;
  border-width: 10px;
  top: 10px;
}

.cbp_tmtimeline > li .cbp_tmlabel blockquote {
  font-size: 16px;
}

.cbp_tmtimeline > li .cbp_tmlabel .map-checkin {
  border: 5px solid rgba(235, 235, 235, 0.2);
  -moz-box-shadow: 0px 0px 0px 1px #ebebeb;
  -webkit-box-shadow: 0px 0px 0px 1px #ebebeb;
  box-shadow: 0px 0px 0px 1px #ebebeb;
  background: #fff !important;
}

.cbp_tmtimeline > li .cbp_tmlabel h2 {
  margin: 0px;
  padding: 0 0 10px 0;
  line-height: 26px;
  font-size: 16px;
  font-weight: normal;
}

.cbp_tmtimeline > li .cbp_tmlabel h2 a {
  font-size: 15px;
}

.cbp_tmtimeline > li .cbp_tmlabel h2 a:hover {
  text-decoration: none;
}

.cbp_tmtimeline > li .cbp_tmlabel h2 span {
  font-size: 15px;
}

.cbp_tmtimeline > li .cbp_tmlabel p {
  color: #444;
}

.cbp_tmtimeline > li .cbp_tmicon {
  width: 40px;
  height: 40px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 1.4em;
  line-height: 40px;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  color: #fff;
  background: #46a4da;
  border-radius: 50%;
  box-shadow: 0 0 0 5px #f5f5f6;
  text-align: center;
  left: 20%;
  top: 0;
  margin: 0 0 0 -25px;
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .cbp_tmtimeline > li .cbp_tmtime {
    padding-right: 60px;
  }
}

@media screen and (max-width: 65.375em) {
  .cbp_tmtimeline > li .cbp_tmtime span:last-child {
    font-size: 12px;
  }
}

@media screen and (max-width: 47.2em) {
  .cbp_tmtimeline:before {
    display: none;
  }
  .cbp_tmtimeline > li .cbp_tmtime {
    width: 100%;
    position: relative;
    padding: 0 0 20px 0;
  }
  .cbp_tmtimeline > li .cbp_tmtime span {
    text-align: left;
  }
  .cbp_tmtimeline > li .cbp_tmlabel {
    margin: 0 0 30px 0;
    padding: 1em;
    font-weight: 400;
    font-size: 95%;
  }
  .cbp_tmtimeline > li .cbp_tmlabel:after {
    right: auto;
    left: 20px;
    border-right-color: transparent;
    border-bottom-color: #f5f5f6;
    top: -20px;
  }
  .cbp_tmtimeline > li .cbp_tmicon {
    position: relative;
    float: right;
    left: auto;
    margin: -64px 5px 0 0px;
  }
  .cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
    border-right-color: transparent;
    border-bottom-color: #f5f5f6;
  }
}

.bg-green {
  background-color: #50d38a !important;
  color: #fff;
}

.bg-blush {
  background-color: #ff758e !important;
  color: #fff;
}

.bg-orange {
  background-color: #ffc323 !important;
  color: #fff;
}

.bg-info {
  background-color: #2ca8ff !important;
}

.css-4je539-control {
  border-color: #d1d3e2;
}
.parent-tracking-timeline-items {
  display: flex;
}
.parent-tracking-timeline-items .item-tracking div.label-content label {
  font-weight: 400;
  font-size: 12px;
}
.parent-tracking-timeline-items .item-tracking div.label-content p {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}
.parent-tracking-timeline-items .item-tracking div:first-child {
  display: flex;
  justify-items: first baseline;
  justify-content: flex-end;
  margin-right: 16px;
  align-items: center;
}
.parent-tracking-timeline-items .item-tracking {
  align-content: center;
}
.page-link {
  cursor: pointer;
}

div.pac-container {
  z-index: 1050 !important;
}

.ui-autocomplete {
  z-index: 1050 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] { -moz-appearance:textfield; }

.search-field {
  min-width: 10em;
  max-width: 17em;
  width: 15vw;
  display: flex;
  flex-direction: column;
}

[data-title] {
  font-size: 18px;
  position: relative;
  cursor: help;
}

[data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  bottom: -46px;
  padding: 10px;
  background: #000;
  color: #fff;
  font-size: 14px;
  width: 300px;
  height: auto;
  white-space: normal;
}
[data-title]:hover::after {
  content: '';
  position: absolute;
  bottom: -12px;
  left: 8px;
  border: 8px solid transparent;
  border-bottom: 8px solid #000;
}
.stickyAddress {
  overflow-x: auto;
}

.stickyAddress th,
.stickyAddress td {
  position: sticky;
  padding: 10px;
  font-weight: bold;
  text-align: left;
  white-space: nowrap;
}

.stickyAddress th:first-child,
.stickyAddress td:first-child {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 2;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.stickyAddress th:last-child,
.stickyAddress td:last-child {
  position: sticky;
  right: 0;
  background-color: white;
  z-index: 2;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.stickyAddress thead th:first-child,
.stickyAddress tbody td:first-child {
  z-index: 3;
}

.stickyAddress thead th:last-child,
.stickyAddress tbody td:last-child {
  z-index: 1;
}

.react-datepicker-popper {
  z-index: 999;
}

.stickyAddress::-webkit-scrollbar {
  height: 8px;
}

.stickyAddress::-webkit-scrollbar-track {
  background-color: white;
}

.stickyAddress::-webkit-scrollbar-thumb {
  background-color: lightgray;
}

.react-datepicker-wrapper {
  display: block !important;
}

.error-message{
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e74a3b;
}

.red-light{
  background-color: red;
  color: white;
}

.yellow-light{
  background-color: yellow;
  color: black;
}

.green-light{
  background-color: green;
  color: white;
}

.orange-light{
  background-color: orange;
  color: black;
}

.blue-light{
  background-color: rgb(79, 194, 233);
  color: black;
}

.product-name {
  display: inline-block;
  max-width: 100px; /* Ajusta el ancho máximo inicial según sea necesario */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: max-width 0.3s ease;
}

.product-name:hover {
  max-width: 300px; /* Ajusta el ancho expandido según sea necesario */
  overflow: visible;
  white-space: nowrap;
  position: relative;
  z-index: 1; /* Asegura que el texto expandido se muestre por encima de otros elementos */
  background-color: white; /* Opcional: para mejorar la legibilidad */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); 
}

/* src/components/Modal/WMS/Incomings/Returns/index.css */

.incrementable-container {
  display: flex;
  align-items: center;
  width: 78%;
  margin: 0 auto;
}

.incrementable-input {
  width: 60px;
  text-align: center;
}

.incrementable-buttons {
  display: flex;
  flex-direction: column;
}

.increment-button, .decrement-button {
  padding: 0.25rem 0.5rem;
  border-radius: 0;
}

.increment-button {
  border-bottom: none;
  border-radius: 4px 4px 0 0;
}

.decrement-button {
  border-radius: 0 0 4px 4px;
}

.product-grid-container {
  margin-bottom: 20px; 
}

.product-info {
  margin-bottom: -20px;
}

.product-info + .grid {
  margin-top: 0; /* Asegura que la tabla esté pegada al producto/cantidad */
}

.modal-error-size {
  max-width: 530px;
}

/* Media query para pantallas de 1024px o menos */
@media (max-width: 1024px) {
  .incrementable-container {
    max-width: 150px;
  }

  .incrementable-input {
    width: 50px;
  }

  .increment-button, .decrement-button {
    padding: 0.2rem 0.4rem;
  }
}